/* Global variables adn mixins are defined here */

::ng-deep .cdk-global-scrollblock {
  overflow: hidden;
}

::ng-deep mat-pseudo-checkbox {
  width: 0px !important;
}

::ng-deep .mat-mdc-dialog-container .mdc-dialog__surface {
  width: auto !important;
  // height: auto !important;
}

// ::ng-deep mat-option{
//     display: flex !important;
//     justify-content: center !important;
// }
::ng-deep .mdc-list-item__primary-text {
  margin: 0px !important;
}

::ng-deep .mdc-tab__ripple {
  display: none;
}

::ng-deep .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled~.mdc-checkbox__background {
  background-color: #3e97ff !important;
  border-color: #3e97ff !important;
}

.toaster {
  background-color: #f4f7ff !important;
}

/* variables */

$primary: #3e97ff;
$primary-active: #2884ef;
$primary-light: #f2f4ff;
$success: #50cd89;
$success-active: #50cd89;
$success-light: #e8fff3;
$danger: #ff4b55;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$info: #7dcfdb;
$info-active: #4fc9da;
$info-light: #edf9fb;
$warning: #ffa800;
$warning-active: #ffa800;
$warning-light: #fff7e7;
$white: #ffffff;
$white-active: #f5f8fa;
$dark: #181c32;
$gray-100: #f9f9f9;
$gray-200: #f1f1f2;
$gray-300: #e1e3ea;
$gray-400: #d8d8e5;
$gray-500: #959cbd;
$gray-600: #7e8299;
$gray-700: #5e6278;
$gray-800: #3f4254;
$gray-900: #181c32;
$gray-button: #1e2238;

//
$primary-clr-dark: #232f3e;
$primary-clr: #3e97ff;
$primary-clr-alpha: #00a8e1;
$bg-clr: #f3f3f3;
$bg-clr-alpha: #edeced;
$primary-ft-clr: #595a5c;
$primary-grey: #707070;
$white: #ffffff;
$black: #3f4254;
// $success: #347439;
$failure: #ff0000;
$grey: #cccccc;
$primary-font: "Poppins", sans-serif;
$ft-thin: 300;
$ft-regular: 400;
$ft-500: 500;
$ft-bold: 700;
$ft-semibold: 600;

/* MIXINS */

/* mixin for border radius */

@mixin border-radius($top, $right, $bottom, $left) {
  -webkit-border-radius: $top $right $bottom $left;
  -moz-border-radius: $top $right $bottom $left;
  border-radius: $top $right $bottom $left;
}

@mixin border-single-radius($rad) {
  -webkit-border-radius: $rad;
  -moz-border-radius: $rad;
  border-radius: $rad;
}

/* To create a section at the center of window */
@mixin create-centered-section($width,
  $border-radius,
  $padding-tb,
  $padding-lr,
  $border,
  $background,
  $left) {
  position: absolute;
  margin: auto;
  top: 0;
  left: $left;
  right: 0;
  bottom: 0;
  width: $width;
  height: 50vh;
  // max-height: 520px;
  border-radius: $border-radius;
  padding: $padding-tb $padding-lr;
  border: $border;
  background: $background;
}

/* To include and adjust background image */
@mixin set-background-image($bg-color,
  $bg-url,
  $bg-position,
  $bg-repeat,
  $bg-size) {
  background: $bg-color;
  background-image: $bg-url;
  background-position: $bg-position;
  background-repeat: $bg-repeat;
  background-size: $bg-size;
}

/* to split the input */
@mixin split-input($ltr-space,
  $bg-img,
  $bg-position,
  $bg-size,
  $bg-repeat,
  $bg-pos-x) {
  border: 0;
  letter-spacing: $ltr-space;
  background-image: $bg-img;
  background-position: bottom;
  background-size: $bg-size;
  background-repeat: $bg-repeat;
  background-position-x: $bg-pos-x;
}

/* box-shadow generator
    $clr- in RGBA format
 */

@mixin box-shadows($hset, $vset, $blur, $spread, $clr) {
  -webkit-box-shadow: $hset $vset $blur $spread $clr;
  -moz-box-shadow: $hset $vset $blur $spread $clr;
  box-shadow: $hset $vset $blur $spread $clr;
}

/*
remove the autofilled background color
*/
@mixin remove-autofill-background() {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition-delay: 99999s;
    -webkit-transition-delay: 99999s;
  }
}

/* mixin for create buttons */

@mixin create-buttons($bgClr,
  $clr,
  $ptb,
  $plr,
  $width,
  $height,
  $ft-size,
  $ft-weight,
  $rad) {
  display: inline-block;
  // padding: $ptb $plr;
  margin-bottom: 0;
  line-height: calc($height/2);
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  @include border-single-radius($rad);
  font-size: $ft-size;
  font-weight: $ft-weight;
  height: $height;
  min-width: $width;
  background: $bgClr;
  color: $clr;
  position: relative;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus,
  &:active {
    // background: $clr;
    // color: $bgClr;
    // border: 1px solid $bgClr;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    text-decoration: none;
  }
}

@mixin no-transition($bg-color, $color) {

  &:hover,
  &:focus,
  &:active {
    background: $bg-color;
    color: $color;
    border: 1px solid $bg-color;
  }
}

/* generate margin & padding */
@mixin generate-margin($tp, $rt, $bt, $lt) {
  margin: $tp $rt $bt $lt;
}

@mixin generate-padding($tp, $rt, $bt, $lt) {
  padding: $tp $rt $bt $lt;
}

/* to set the table row */
@mixin set-table-row($bg-color,
  $color,
  $border,
  $font-size,
  $font-weight,
  $fontFamily: ""

) {
  background-color: $bg-color;
  color: $color;
  border: $border;
  font-size: $font-size;
  // font-weight: $font-weight;
  font-weight: 500;
  font-family: "Amazon Ember Display Light";

  @if $fontFamily !="" {
    font-family: $fontFamily;
    font-weight: $font-weight;
  }
}

/* to set the image */
@mixin set-image-config($width, $height, $cursor, $margin-right) {
  width: $width;
  height: $height;
  cursor: $cursor;
  margin-right: $margin-right;
}

/* to set the styles for first and last child div */
@mixin set-child-div($color1, $font-size1, $font-weight1, $color2) {
  div:first-child {
    color: $color1;
    font-size: $font-size1;
    font-weight: $font-weight1;
  }

  div:last-child {
    color: $color2;
  }
}

/* to set the font style */
@mixin set-font-config($fontSize,
  $fontWeight,
  $fontColor,
  $textAlign,
  $fontFamily: ""

) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $fontColor;
  text-align: $textAlign;

  @if $fontFamily !="" {
    font-family: $fontFamily;
  }
}

.ast-table {
  .table {
    margin-bottom: 0px;
  }

  table {
    thead {

      // @mixin set-table-row($bg-color, $color, $border, $font-size, $font-weight)
      // @include set-table-row($white, #231f20, 0.5px solid #e2e2e1, 16px, 300) ;
      th {
        background-color: #edeced;
        border: 1px solid #dee2e6;
        border-top: none !important;
      }
    }

    td {
      // @mixin set-table-row($bg-color, $color, $border, $font-size, $font-weight)
      @include set-table-row(#fafafa, #231f20, 0.5px solid #e2e2e1, 16px, 300);
    }
  }
}

/* To create a card at the center */
@mixin create-card($width,
  $height,
  $border-radius,
  $padding-tb,
  $padding-lr,
  $border,
  $background,
  $left) {
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: $width;
  height: $height;
  border-radius: $border-radius;
  padding: $padding-tb $padding-lr;
  border: $border;
  background: $background;
  text-align: center;
}

@mixin flexbox($display, $align-items, $justify-content) {
  display: $display;
  align-items: $align-items;
  justify-content: $justify-content;
}

::ng-deep mat-pseudo-checkbox {
  display: none !important;
}

::ng-deep .mat-calendar-body-selected {
  background-color: $primary !important;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom: none !important;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom: none !important;
}

::ng-deep .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  background-color: $primary-light !important;
}

::ng-deep .mat-form-field {
  border: none !important;
}

.dateselect {
  mat-form-field {
    display: flex;
    height: 25px;
    width: 185px;
    font-size: 12px;
    text-align: center;
    height: 24px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-top: 7px;
    margin-left: 8px;

    mat-date-range-input {
      ::ng-deep .mat-date-range-input-separator {
        color: #959cbd !important;
      }

      color: #959cbd !important;
      display: flex;
      justify-self: center;
    }

    ::ng-deep .mat-date-range-input-content {
      border-bottom: none !important;
    }

    mat-label {
      font-size: 12px;
    }

    ::ng-deep mat-datepicker-toggle {
      background-image: url(../images/calendar-icon.svg);
      background-repeat: no-repeat;

      button {
        margin-left: 35px;
        width: 20px !important;
      }

      .mat-mdc-icon-button svg {
        display: none !important;
      }
    }
  }
}

::ng-deep .mat-mdc-form-field-icon-suffix,
[dir="rtl"] .mat-mdc-form-field-icon-prefix {
  width: 30px !important;
  margin-top: 10px;
}

::ng-deep .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  align-items: center;
  border-radius: 5px;
}

::ng-deep .mat-mdc-icon-button {
  --mat-icon-button-hover-state-layer-opacity: 0 !important;
}

::ng-deep .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  display: none;
}

.tab-header {
  ::ng-deep .mat-mdc-tab-list {
    align-content: center;
  }

  ::ng-deep .mat-mdc-tab-links {
    gap: 5px;
  }
}


/** Date Picker **/

::ng-deep mat-datepicker-toggle {
  width: 0 !important;

  button {
    position: absolute;
    /* margin-bottom: -20px; */
    top: -23% !important;
    right: 40px;
  }
}

.date-picker {
  height: 25px;
  min-width: 180px;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 7px 0;

  mat-form-field {
    width: 210px;
  }

  ::ng-deep .mdc-text-field {
    padding-left: 0 !important;
  }

  ::ng-deep .mdc-text-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    padding: 0 !important;
  }

  ::ng-deep .mdc-text-field .mat-mdc-form-field-flex {
    height: 23px;
  }

  ::ng-deep .mdc-text-field .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
    width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  mat-date-range-input {
    color: #959cbd !important;
    display: flex;
    justify-self: center;

    ::ng-deep .mat-date-range-input-separator {
      color: #959cbd !important;
      // margin: 0 10px;
    }

    ::ng-deep .mat-date-range-input-inner {
      letter-spacing: 0.5px;
    }

    ::ng-deep .mat-date-range-input-container {
      justify-content: center;
      font-family: "Poppins";
      width: fit-content;
      margin: 0 10px;
    }

    ::ng-deep .mat-date-range-input-content {
      border-bottom: none !important;
    }

    ::ng-deep .mat-date-range-input-end-wrapper {
      flex-grow: initial !important;
    }

    ::ng-deep .mat-date-range-input-wrapper {
      width: 70px;
    }
  }

  mat-label {
    font-size: 12px;
  }

  ::ng-deep mat-datepicker-toggle {
    button {
      width: 24px !important;
      height: 24px !important;
      padding: 0 !important;
      transform: translateX(10px);
      background-image: url(../images/calendar-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    .mat-mdc-icon-button svg {
      display: none !important;
    }
  }
}

/** Date Picker **/


.back-button,
.close-button {
  width: 35px;
  height: 35px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all .25s;
}

.back-button {
  background-image: url('../images/back.svg');

  &:hover {
    background-image: url('../images/back-hover.svg');
  }
}

.close-button {
  background-image: url('../images/close-circle.svg');

  &:hover {
    background-image: url('../images/close-cirlce-hover.svg');
  }
}