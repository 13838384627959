/* You can add global styles to this file, and also import other style files */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import "@angular/material/theming";
@use "@angular/material" as mat;
@import "@ng-select/ng-select/themes/material.theme.css";
@import "./assets/styles/global.scss";
@import "./assets/styles/common.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";

@font-face {
  font-family: "Amazon Ember Display Light";
  src: url("./assets/fonts/amazon_light.eot");
  src: url("./assets/fonts/amazon_light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/amazon_light.woff2") format("woff2"),
    url("./assets/fonts/amazon_light.woff") format("woff"),
    url("./assets/fonts/amazon_light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Amazon Ember Medium";
  src: url("./assets/fonts/amazon_medium.eot");
  src: url("./assets/fonts/amazon_medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/amazon_medium.woff2") format("woff2"),
    url("./assets/fonts/amazon_medium.woff") format("woff"),
    url("./assets/fonts/amazon_medium.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}

html,
body {
  height: 100%;
  overflow: hidden;
  font-family: $primary-font;
}

body {
  margin: 0;
  font-family: $primary-font !important;
  background: #f3f6f9 !important;
}

.no-gutter {
  margin-right: 0 !important;
  margin-left: 0 !important;

  [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

input:-internal-autofill-selected {
  color: $primary-clr-dark !important;
  opacity: 0.7 !important;
}

.no-padding {
  padding: 0px;
}

.wordbreak {
  word-break: break-word;
}

.emp-input {
  .ng-select.ng-select-single .ng-select-container {
    height: 36px;
    margin-top: 2px;
  }
}

/* common styles */
.header {
  height: 80px;
  width: 100%;
  background-color: $white;
}

.required:after {
  content: " *";
  color: $failure;
}

p {
  margin-bottom: 0px !important;
}

/* Override bootstrap styles */
.form-control {
  outline: none;

  &:focus {
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
  }
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.error-section {
  min-height: 15px;

  // p,
  .err-msg {
    margin-bottom: 0px;
  }
}

/* Override material styles */
.error-section .err-msg,
// .error-section p,
mat-hint {
  color: #f44336 !important;
  letter-spacing: 0.5px;
  font-family: "Amazon Ember Display Light";
  font-size: 12px;
}

// change the undeline of disabled mat-input
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right,
      #cccccc 0%,
      #cccccc 100%,
      transparent 0%);
  cursor: no-drop;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #cccccc;
  box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
}

.mat-slide-toggle-thumb {
  height: 15px !important;
  width: 15px !important;
}

.mat-slide-toggle-bar {
  // width: 35px !important;
  height: 24px !important;
  border-radius: 12px !important;
}

.mat-slide-toggle-bar {
  background-color: #00bcd4;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $white;
}

.mat-slide-toggle-thumb-container {
  top: 4px !important;
  left: 4px !important;
}

.mat-slide-toggle .mat-slide-toggle-ripple {
  top: calc(50% - 10px) !important;
  left: calc(50% - 10px) !important;
  height: 20px !important;
  width: 20px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(13px, 0, 0) !important;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: $primary-clr-alpha !important;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 4px;
  border-radius: 5px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.mat-slider-thumb,
.mat-accent .mat-slider-thumb {
  width: 45px !important;
  height: 45px !important;
  bottom: -24px;
  right: -18px;
}

.mat-slider-focus-ring,
.mat-accent .mat-slider-focus-ring {
  width: 0px !important;
  height: 0px !important;
}

.mat-menu-panel {
  min-height: 50px !important;
  border-radius: 0px;
}

.dropdown-item:active {
  background-color: $white !important;
  color: $primary-clr-dark;
}

.mat-menu-content {
  cursor: pointer !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $primary-clr-alpha;
}

.hidden-sm-up {
  display: none;
}

.hidden-md-down {
  display: inline-block;
}

.layout-action {
  button:nth-child(1) {
    margin-left: 30px !important;
  }

  button:not(:first-child) {
    margin-left: 10px;
  }
}

.custom-search {
  display: inline-block;
  width: 30%;
  max-width: 300px;

  .input-group,
  .form-group {
    position: relative;

    .form-control {
      border: none;
      border-radius: 5px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: $white;
      font-size: 14px;
      padding-right: 30px;
    }

    img {
      position: absolute;
      margin-left: -25px;
      margin-top: 9px;
      width: 18px;
    }
  }
}

.custom-filters .form-group:not(:first-child) {
  margin-left: 20px;
}

.select-drop-down {
  .ng-select-container {
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: none;
  }

  .ng-select-container:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
}

.add-associate-drop-down {
  .ng-select-container {
    border-radius: 5px;
    box-shadow: none;
    min-height: 12px;
    height: 36px !important;

    .ng-placeholder {
      font-size: 12px;
      margin-bottom: 8px !important;
    }

    .ng-value {
      font-size: 12px !important;
      color: #535353;
      margin-top: 2px;
    }

    .ng-clear-wrapper {
      margin-top: 5px;
    }
  }

  .ng-select-container:hover {
    box-shadow: none;
  }

  .ng-dropdown-panel .scroll-host {
    height: 14vh;
  }
}

.custom-filters {
  .form-group {
    width: 20%;
    max-width: 300px;
    display: inline-block;
  }

  .mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: $white;
    padding: 0px 10px;
  }

  .mat-form-field {
    font-size: 14px;
    min-width: 100px !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-hide-placeholder .mat-select-placeholder {
    color: #cccccc;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 7px 0px 9px;
    margin-top: -5px;
  }
}

// confirm delete modal
.edit-device-dialog,
.confirm-delete-dialog {
  .mat-dialog-container {
    border-radius: 10px !important;
    padding: 0px;
  }
}

.confirm-delete-dialog {
  .mat-dialog-container {
    width: 450px;
  }
}

.pwd-reset-dialog {
  .mat-dialog-container {
    padding: 0px;
  }
}

// download assets delete modal
// .add-associate-dialog {
//   max-width: 100vw !important;
//   // min-height: 50vh !important;
//   position: absolute !important;
//   left: 50px !important;
//   bottom: 50px !important;

//   .mat-dialog-container {
//     max-width: 100% !important;
//     //   width: 100vw;
//     height: 100% !important;
//     padding: 0px;
//     border-radius: 10px;
//     box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.16);
//     background: $white;
//   }
// }

.change-pwd-dialog {
  .change-password-container {
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
  }
}

// override ngx-pagination styles

.ngx-pagination li,
.ngx-pagination .ng-star-inserted,
.mat-datepicker-toggle {

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.ngx-pagination {
  li:not(:last-child) {
    border-right: none !important;
  }
}

/* Override ngx-pagination styles */
pagination-controls {
  display: inline-block;
}

.ngx-pagination {
  ul {
    margin-bottom: 0 !important;
  }

  li {
    margin-right: 0 !important;
    // border: solid 1px #aaaaab !important;
    border-radius: 15px !important;

    &.current {
      background-color: $primary-clr-alpha !important;
      color: $white;
    }
  }

  li.disabled {
    cursor: not-allowed !important;
    // opacity: 0.5;
  }

  a {
    text-decoration: none;
    border-radius: 15px !important;
    color: #5a5a5a !important;
  }

  .pagination-previous,
  .pagination-previous .disable,
  .pagination-next,
  .pagination-next .disabled {
    a {

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        background: none;
      }
    }
  }

  .pagination-next,
  .pagination-next .disabled {
    a {
      padding-left: 1px !important;
    }
  }

  .pagination-previous,
  .pagination-previous .disable {
    padding-right: 1px !important;

    a {
      padding-right: 1px !important;
    }
  }

  .pagination-previous a:hover::before {
    content: url("../src/assets/images/page-prev-hover.svg") !important;
    padding-right: 1px !important;
    position: relative;
    top: 3px;
    filter: none;
    // opacity: 0.5;
  }

  // .pagination-previous.disabled::before {
  //   content: url('../src/assets/images/page-prev.svg') !important;
  //   padding-right: 1px !important;
  //   position: relative;
  //   top: 3px;
  //   filter: grayscale(0.4) invert(0.4);

  // }
  .pagination-previous a::before {
    content: url("../src/assets/images/page-prev.svg") !important;
    padding-right: 1px !important;
    position: relative;
    top: 3px;
    filter: grayscale(0.4) invert(0.4);
    // opacity: 0.5;
  }

  .pagination-previous.disabled::before {
    content: url("../src/assets/images/page-prev.svg") !important;
    padding-right: 1px !important;
    position: relative;
    top: 3px;
    filter: grayscale(0.4) invert(0.4);
  }

  .pagination-next a::after,
  .pagination-next.disabled::after {
    content: url("../src/assets/images/page-next.svg") !important;
    padding-left: 1px !important;
    position: relative;
    top: 3px;
    filter: grayscale(0.4) invert(0.4);
  }

  .pagination-next a:hover::after {
    content: url("../src/assets/images/page-next-hover.svg") !important;
    padding-left: 1px !important;
    position: relative;
    top: 3px;
    filter: none;
  }

  .pagination-previous.disabled::before,
  .pagination-next.disabled::after {
    opacity: 0.5;
  }
}

.my-pagination {
  float: right;
  padding: 15px 0;
}

// to align the dialog to the bottom
div.add-associate-backdrop+div.cdk-global-overlay-wrapper {
  display: flex !important;
  align-items: flex-end !important;
}

.custom-backdrop,
.confirm-delete-backdrop,
.add-associate-backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
}

.event-selector {
  border-radius: 0 !important;

  // margin-top: 36px !important;
  .mat-option.mat-active {
    color: $white !important;
    background-color: $primary-clr-alpha !important;

    .mat-pseudo-checkbox-checked {
      background-color: $white !important;
    }

    .mat-pseudo-checkbox::after {
      color: $primary-clr-alpha !important;
    }
  }

  .mat-option {
    height: 60px !important;
  }
}

input.mat-input-element,
.mat-form-field {
  caret-color: $primary-clr-alpha !important;

  &:disabled {
    cursor: not-allowed;
  }
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $primary-clr-alpha;
}

.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background: $primary-clr-alpha !important;
}

.each-module {
  .mat-checkbox .mat-checkbox-ripple {
    height: 0px;
    width: 0px;
  }

  .mat-checkbox-inner-container {
    margin-right: 15px;
  }

  .each-resource .each-row-cls {

    .mat-checkbox-background,
    .mat-checkbox-frame {
      border-radius: 10px;
    }

    .mat-checkbox-checkmark {
      top: 1px;
      width: 95%;
    }
  }
}

.mat-card {
  font-family: $primary-font;
}

.mat-drawer-content {
  overflow: hidden !important;
}

// .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
//   background-color: $primary-clr-alpha;
// }
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-primary .mat-pseudo-checkbox-checked {
  background-color: $primary-clr-alpha;
}

.mat-checkbox-frame {
  border-width: 0px;
  border-style: none;
  border-radius: 0px;
  border: 1px solid #888888 !important;
}

.customise-snackbar {
  color: $primary-clr-dark;
  background-color: $white;

  .mat-simple-snackbar-action {
    color: $primary-clr-alpha;
  }

  .mat-simple-snackbar-action button:focus,
  .mat-simple-snackbar-action button:active {
    outline: none !important;
    background-color: white !important;
  }
}

.side-menu {

  .associate,
  .settings {
    img {
      margin-left: -6px !important;
    }
  }
}

// .mat-tab-links {
//     box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
// }

.tab-header {
  padding-left: 21px;
}

.tab-active {
  border-bottom: 2px solid $primary-clr-alpha;
}

.route-active {
  color: $primary-clr-alpha !important;
}

.flex-container {
  display: -webkit-flex;
  display: flex;
  justify-content: space-around;
}

.table-responsive {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  background-color: $white;
}

// to give a scroll to table body
.table-scroll {
  border: 1px solid #ddd;
  margin-bottom: 0px;

  thead,
  tbody tr,
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    // border-bottom: 1px solid #ddd !important;
  }

  th,
  td {
    border: none;
    text-align: left;
  }

  th {
    font-size: 16px;
    color: #595a5c;
  }

  td {
    font-size: 14px;
    padding: 0.5rem 0.75em;
  }

  thead tr {
    // background: #edeced;

    th {
      font-weight: 500;
      background: transparent;
      vertical-align: middle;
    }
  }

  tbody {
    display: block;
    overflow-y: overlay;
    max-height: calc(100vh - 256px);
    min-height: 50px;

    tr:nth-of-type(even) {
      background-color: rgba(51, 51, 51, 0.05) !important;
    }

    tr:last-child td {
      border-bottom: none !important;
    }

    td {
      border-top: none !important;
      word-break: break-word;
      word-break: break-all;
      font-weight: 300;
      color: #808080;
    }
  }

  // tbody.no-data{
  //     min-height: auto !important;
  // }
}

/** Styles specific to mozilla */
@-moz-document url-prefix() {
  .table-scroll {
    border: none;

    thead tr,
    tbody tr {
      border: 1px solid #ddd;
    }

    thead {
      width: calc(100% - 1em);

      tr {
        border-bottom: none !important;
      }
    }

    tbody {
      overflow-y: scroll !important;

      tr {
        border-top: none !important;
      }

      // overflow-x: auto;
    }
  }

  /*scroll bar */
  ::-webkit-scrollbar {
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
}

/*loader*/
.loader-overlay,
.loader-table,
.loader-table-cell,
.loading:before {
  width: 100%;
  height: 100%;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  color: $primary-clr-alpha;
  background: $primary-clr-dark;

  .loader-table {
    display: table;
    text-align: center;
  }

  .loader-table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999;
  height: 2em;
  width: 2em;
  overflow: show;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 8px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 2000ms infinite linear;
  -moz-animation: spinner 2000ms infinite linear;
  -ms-animation: spinner 2000ms infinite linear;
  -o-animation: spinner 2000ms infinite linear;
  animation: spinner 2000ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(3, 75, 130) 1.5em 0 0 0,
    rgba(3, 75, 130) 1.1em 1.1em 0 0, rgba(3, 75, 130) 0 1.5em 0 0,
    rgba(3, 75, 130) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(3, 75, 130) 0 -1.5em 0 0,
    rgba(3, 75, 130) 1.1em -1.1em 0 0;
  box-shadow: rgba(3, 75, 130) 1.5em 0 0 0, rgba(3, 75, 130) 1.1em 1.1em 0 0,
    rgba(3, 75, 130) 0 1.5em 0 0, rgba(3, 75, 130) -1.1em 1.1em 0 0,
    rgba(3, 75, 130) -1.5em 0 0 0, rgba(3, 75, 130) -1.1em -1.1em 0 0,
    rgba(3, 75, 130) 0 -1.5em 0 0, rgba(3, 75, 130) 1.1em -1.1em 0 0;
}

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999;
  height: 2em;
  width: 2em;
  overflow: show;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.loader::before {
  animation-delay: 1s;
}

.loader::after {
  animation-delay: 0.5s !important;
}

.loader:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.loader:not(:required)::before,
.loader:not(:required):after {
  content: "";
  border: 5px solid $gray-200;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  display: block;
  animation: loader 1s linear infinite;
  opacity: 0;
}

@keyframes loader {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}


//toaster
.toaster {
  position: fixed;
  bottom: 80%;
  left: 50%;
  z-index: 999999;
  transform: translateX(-50%);
  background-color: $white;
  color: #fff;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
  // display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
}

.bar {
  display: flex;
  align-items: center;
}

.closeImg {
  display: flex;
  margin: 0px 0px 0px auto;
  justify-content: flex-end;

  img {
    width: 18px;
    height: 18px;
  }
}

.message {
  color: $dark;
}

.second {
  display: flex;
  gap: 15px;
  padding: 10px;
  align-items: center;
}

.progress-bar {
  height: 5px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  animation: progressBar 5s linear;
}

@keyframes progressBar {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

/* Media queries - start */
@media (min-width: 767px) and (max-width: 1441px) {
  .my-pagination {
    font-size: 14px;
  }

  .table-scroll {

    .table td,
    .table th {
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .my-pagination {
    font-size: 13px;
  }

  .table-scroll {

    .table td,
    .table th {
      font-size: 14px;
    }
  }

  .custom-search {
    width: 50%;
  }

  .hidden-sm-up {
    display: inline-block;
  }

  .hidden-md-down {
    display: none;
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0px 10px;
}

.mdc-menu-surface,
.scroll-bar,
.download-notification .mat-mdc-menu-content,
div,
mat-toolbar {
  &::-webkit-scrollbar {
    appearance: none;
    width: 8px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
}

/* Media queries - end*/

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/bootstrap";